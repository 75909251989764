import React from "react"
import styled from "@emotion/styled"

import Cta from "./Cta"
import Paper from "./Paper"

const Container = styled.section`
  max-width: 130rem;
  padding: 3rem 1.5rem;
  margin: 0 auto;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #2196f3;
  background: linear-gradient(to right, #2196f3, #2dc7ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const Content = styled.div`
  padding: 3rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  @media only screen and (max-width: 32.5em) {
    justify-content: flex-start;
  }
  .paper {
    width: 100%;
    max-width: 45rem;
    padding: 2rem 2rem;
    ul {
      max-width: 35rem;
    }
    :nth-child(-n+2) {
      background-image: linear-gradient(to right, #cfcfcf 33%, rgba(207,207,207,0) 0%);
      background-position: bottom;
      background-size: 15px 1px;
      background-repeat: repeat-x;
    }
    :nth-of-type(odd) {
      border-right: 1px solid #cfcfcf;
    }
    @media only screen and (max-width: 59em) {
      border: none !important;
      background: none !important;
    }
  }
`

const Body = styled.p`
  padding: 1rem 0;
  font-weight: 400;
  color: #505050;
`

const List = styled.ul`
  list-style: none;
  text-align: left;
  width: 100%;
  max-width: 35rem;
  margin: 0 auto;
  padding: 1rem 0;
  @media only screen and (max-width: 40em) {
    grid-column: auto;
  }
`

const Yes = styled.li`
  position: relative;
  padding-left: 2rem;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 5%;
    width: 0.75rem;
    height: 1.5rem;
    border: solid #2196f3;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`

const No = styled.li`
  position: relative;
  padding-left: 2rem;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    height: 3px;
    width: 1.5rem;
    background-color: #ef5350;
  }
  &::before {
    transform: translateY(-50%) rotate(45deg);
  }
  &::after {
    transform: translateY(-50%) rotate(-45deg);
  }
`

const InlineButtonsContainer = styled.span`
  .cta-container {
    padding-top: 1rem;
  }
  @media only screen and (min-width: 34em) {
    padding: 1rem 10% 0;
    .cta-container {
      display: inline;
      padding: 0 .5rem;

      button {
        display: inline;
      }
    }
  }
`

const WideButtonContainer = styled.span`
  @media only screen and (min-width: 34em) {
    padding: 0 10%;
    .cta-container button {
        width: 95%
    }
  }
`

const Recycling = ({ data: { title, subtitle, data } }) => {
  return (
    <Container>
      <Primary>{title}</Primary>
      <Subtitle>{subtitle}</Subtitle>
      <Content>
        {data &&
          data.map(({ name, body, image, learnUrl }, count) => (
            <Paper
              title={name}
              image={image.relativePath ? `/img/${image.relativePath}` : image}
              key={count}
              cta={
                <React.Fragment>
                  <WideButtonContainer>
                    <Cta to={learnUrl} variant>
                        Learn more
                    </Cta> 
                  </WideButtonContainer>                  
                  <InlineButtonsContainer>
                    <Cta to="/locations">
                      Locations
                    </Cta>
                    <Cta to="/services">
                      Services
                    </Cta>           
                  </InlineButtonsContainer>
                </React.Fragment>
              }
            >
              <Body>{body}</Body>
            </Paper>
          ))}
      </Content>
    </Container>
  )
}
export default Recycling
